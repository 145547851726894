import React, { useState } from 'react';
import http from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  const [slug, setSlug] = useState('');
  const [url, setUrl] = useState('');
  const [key, setKey] = useState('');

  const notify = (message, config) => toast(message, config);

  const validateProperties = () => {
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const httpConfig = {
      headers: { Authorization: `Bearer ${key}` },
    };

    const valid = validateProperties();
    if (!valid) {
      throw new Error('hi');
    }
    try {
      const response = await http.post('/url', { url, slug }, httpConfig);
      if (response.status === 201) {
        const permalink = response?.data?.permalink || 'Success';
        notify(permalink, { type: 'success', theme: 'dark', autoClose: 10000 });
      }
    } catch (error) {
      const { response } = error;
      const { ...errorObject } = response;
      if (errorObject.status === 400) {
        notify('Slug is al in gebruik', { type: 'error', theme: 'dark' });
      } else if (errorObject.status === 403) {
        notify('Ongeldige sleutel', { type: 'error', theme: 'dark' });
      } else {
        notify('Unexpected error', { type: 'error', theme: 'dark' });
      }
    }
  };

  return (
    <>
      <header>
        <h1 className="gradient-primary">rolf.link</h1>
        <span className="subheader">
          URL shortener for <mark>de Rolf groep</mark> links
        </span>
        <hr className="mb-3" />
      </header>
      <br />
      <section className="mb-3">
        <form>
          <h3 className="gradient-secondary">Slug</h3>
          <input
            type="text"
            required
            name="slug"
            id="slug"
            aria-label="Insert slug"
            placeholder="Slug"
            value={slug}
            onChange={(e) => setSlug(e.target.value)}
          />
          <div className="mb-3 mt-3" />
          <h3 className="gradient-secondary">Nieuwe .link</h3>
          <input
            type="url"
            required
            name="url"
            id="url"
            aria-label="Doel URL"
            placeholder="Target URL"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
          <div className="mb-3 mt-3" />
          <h3 className="gradient-secondary">Sleutel</h3>
          <input
            type="password"
            required
            name="key"
            id="key"
            aria-label="Sleutel"
            placeholder="Beveiligingssleutel"
            value={key}
            onChange={(e) => setKey(e.target.value)}
          />
          <button onClick={handleSubmit} id="submit-btn" className="mt-3 btn-grad" style={{ float: 'right' }}>
            Toevoegen
          </button>
        </form>
      </section>
      <ToastContainer />
    </>
  );
};

export default App;
